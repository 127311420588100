import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { NavLink as NavLinkBase } from 'react-router-dom';
import { Box, CssBaseline, Drawer, List, ListItem, ListItemButton, ListItemIcon, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from './Header';
import { useAuth } from '../../auth/services/AuthContext';
import { routes } from '../../configs/routesConfig';

const useStyles = makeStyles({
   list: {
      // puedes agregar estilos aquí si es necesario
   }
});

// Componente Main
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
   ({ theme, open, isMobile }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      ...(isMobile ? {} : { marginLeft: `-${220}px` }), // No aplicar marginLeft en móviles
      ...(open && !isMobile ? {
         transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
         marginLeft: 0,
      } : {}),
   }),
);

// Componente NavLink
const NavLink = styled((props) => (
   <NavLinkBase {...props} end={props.to === "/"} />
))(() => ({
   textDecoration: "none",
   backgroundColor: 'green',
   fontSize: 15,
   color: '#fff',
   '& .MuiSvgIcon-root': {
      color: '#fff'
   },
   '& .MuiButtonBase-root.MuiListItemButton-root': {
      paddingLeft: '13px'
   },
   '& .MuiButtonBase-root.MuiListItemButton-root:hover': {
      backgroundColor: '#101121',
   },

   "&.active": {
      color: "#101721",
      fontSize: 15,
      fontWeight: 500,

      '& .MuiButtonBase-root.MuiListItemButton-root:hover': {
         backgroundColor: 'transparent',
      },

      '& .MuiListItem-root': {
         borderRadius: 4,
         backgroundColor: '#fff',
         borderLeftWidth: 5,
         borderLeftColor: 'black',
         color: '#101721',
         '& .MuiListItemButton-root': {
            paddingLeft: 8
         },
         '& .MuiSvgIcon-root': {
            color: '#101721',
         },
      }
   },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   padding: theme.spacing(0, 1),
   ...theme.mixins.toolbar,
   justifyContent: 'flex-end',
}));

const mapModulesToRoutes = (modules_roles, routes) => {
   return modules_roles !== null && modules_roles.length > 0 && routes && routes.length > 0 ?
      modules_roles
         .map(item => {
            const route = routes.find(route => route.path === item.fk_module.module_link);
            if (route) {
               return {
                  ...item.fk_module,
                  icon: route ? route.icon : null,
                  // title: route ? route.title : null,
                  translate: route ? route.translate : null,
                  order: item.moro_order,
               };
            }
            return null
         })
         .filter(route => route !== null) // Filtrar los valores nulos
         .sort((a, b) => a.order - b.order)
      : [];
};

export default function CustomDrawer(props) {
   const { contentRoutes } = props;
   const { t } = useTranslation();
   const classes = useStyles();
   const dispatch = useDispatch();
   const { getAccessToken, getModules } = useAuth();
   const token = getAccessToken();
   const modRol = getModules();
   const [open, setOpen] = useState(true);
   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Detectar pantallas pequeñas

   useEffect(() => {
      if (!token) {
         setOpen(true);
      }
   }, [token])

   const toggleDrawer = () => {
      setOpen(!open);
   };

   const handleListItemClick = () => {
      // Cerrar el Drawer al seleccionar un elemento
      if (isMobile) {
         setOpen(false);
      }
   };

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         {token && (
            <>
               <Header toggleDrawer={toggleDrawer} />
               <Drawer
                  sx={{
                     width: 220,
                     flexShrink: 0,
                     '& .MuiDrawer-paper': {
                        width: 220,
                        boxSizing: 'border-box',
                        borderRight: 0,
                        background: '#213462',
                     },
                  }}
                  variant={isMobile ? "temporary" : "persistent"} // Usar temporal en móviles
                  anchor="left"
                  open={open}
                  onClose={isMobile ? toggleDrawer : undefined} // Cerrar el drawer en móviles
               >
                  <DrawerHeader />
                  <List sx={{ p: 1, mt: 1 }} className={classes.list}>
                     {mapModulesToRoutes(modRol, routes).map((e, i) => (
                        <NavLink to={`${e.module_link}`} key={i}>
                           <ListItem disablePadding onClick={handleListItemClick}>
                              <ListItemButton>
                                 <ListItemIcon sx={{ minWidth: 35 }}>
                                    {e.icon}
                                 </ListItemIcon>
                                 <div className="font-medium">{t(`${e.translate}`)}</div>
                              </ListItemButton>
                           </ListItem>
                        </NavLink>
                     ))}
                  </List>
               </Drawer>
            </>
         )}
         <Main open={open} isMobile={isMobile}>
            {token && (<DrawerHeader />)}
            {contentRoutes}
         </Main>
      </Box>
   );
}
