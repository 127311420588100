import React from 'react';
import { Dialog, IconButton, Typography, CircularProgress } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CustomButton from '../CustomButton';

const CustomActionDialog = (props) => {
    const { maxWidth, open, close, fullScreen, icon, title, description, handleAction, titleSuccess, titleCancel, loading } = props;
    return (
        <Dialog
            // disableBackdropClick
            // keepMounted
            disableEscapeKeyDown
            // {...props}
            PaperProps={{
                sx: {
                    // width: "100%",
                    maxWidth: `${maxWidth} !important`,
                },
            }}
            open={open}
            fullScreen={fullScreen}
            // onClose={close}
            className="rounded-24"
            style={{
                boxShadow: "0px 2px 16px 4px rgba(38, 41, 64, 0.1)",
            }}
        >
            <div className="p-1">
                <div className="flex justify-end">
                    <IconButton onClick={close}>
                        <CloseRoundedIcon className="text-48" size={24} color="action" />
                    </IconButton>
                </div>
                <div className="flex flex-col items-center px-10 pb-10">
                    <div>
                        {icon}
                    </div>
                    <div>
                        {title}
                    </div>
                    <div>
                        {description}
                    </div>
                    <div className="flex">
                        {loading ? (
                            <CircularProgress
                                style={{ color: '#4575D5', marginRight: 4 }}
                                size={24}
                            />
                        ) : (
                            <>
                                <div className="flex mr-2">
                                    <CustomButton
                                        label={titleCancel}
                                        className=""
                                        height="medium"
                                        onClick={close}
                                    />
                                </div>
                                <div className="ml-3">
                                    <CustomButton
                                        label={titleSuccess}
                                        className="primary"
                                        height="medium"
                                        onClick={handleAction}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default CustomActionDialog;