import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import WYSIWYGEditor from '@components/WYSIWYGEditor';

import {
  create,
  update,
} from '../../../store/templateSlice';
import { showMessage } from '../../../store/messageSlice';
import CustomSelect from "../../../@components/CustomSelect";
import { getQuestions } from "../../../store/questionSlice";

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
    template_name: '',
    template_description: '',
    template_subject: '',
    template_body: '',
    template_variables: '',
    template_status: 1,
};

const schema = yup.object().shape({
    template_name: yup.string().required('Nombre es requerido'),
    template_description: yup.string().required('Descripcion es requerida'),
    template_subject: yup.string().required('Asunto es requerido'),
    template_body: yup.string().required('Cuerpo es requerido'),
    template_variables: yup.string().required('Variables son requeridas'),
    template_status: yup.number().required('Estado es requerido (1 = Habilitado, 0 = Desabilitado)'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} templateType - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} templateData - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, templateType, templateData, handleRefresh } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit } = methods;
  const { errors } = formState;

  const initDialog = useCallback(async () => {
    reset({
      ...templateData,

    });
  });

  useEffect(() => {
    const fetch = async () => {
      if (templateType === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [templateType, reset, templateData]);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    reset(defaultValues);
  };

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (templateType === 'create') {
      const response = await dispatch(create({
          template_name: data.template_name,
          template_description: data.template_description,
          template_subject: data.template_subject,
          template_body: data.template_body,
          template_variables: data.template_variables,
          template_status: data.template_status
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Plantilla creada', variant: 'success' }));
      }
    } else {
      const response = await dispatch(update({
              template_name: data.template_name,
              template_description: data.template_description,
              template_subject: data.template_subject,
              template_body: data.template_body,
              template_variables: data.template_variables,
              template_status: data.template_status
      },
        templateData.template_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Answer actualizada', variant: 'success' }));
      }
    }
    cleanData();
    setLoading(false);
    handleClose();
    handleRefresh();

  };

  const statusOptions = [
    { value: 1, label: 'Habilitado' },
    { value: 0, label: 'Deshabilitado' },
  ];

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {templateType === 'create' ? 'Crear' : 'Editar'} Plantilla
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
                name="template_name"
                control={control}
                render={({ field }) => (
                    <CustomTextField
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="template_name"
                        label="Nombre de la plantilla"
                        //value={templateType === 'edit' ? templateData.name : ''}
                        error={!!errors.template_name}
                        helperText={errors?.template_name?.message}
                        required
                    />
                )}
            />
            <Controller
                name="template_description"
                control={control}
                render={({ field }) => (
                    <CustomTextField
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="template_description"
                        label="Descripcion de la plantilla"
                        //value={templateType === 'edit' ? templateData.description : ''}
                        error={!!errors.template_description}
                        helperText={errors?.template_description?.message}
                        required
                    />
                )}
            />
            <Controller
                name="template_subject"
                control={control}
                render={({ field }) => (
                    <CustomTextField
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="template_subject"
                        label="Asunto de la plantilla"
                        //value={templateType === 'edit' ? templateData.subject : ''}
                        error={!!errors.template_subject}
                        helperText={errors?.template_subject?.message}
                        required
                    />
                )}
            />
            <Controller
                name="template_variables"
                control={control}
                render={({ field }) => (
                    <CustomTextField
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="template_variables"
                        label="Variables de la plantilla (eg. nombre, fecha, etc...)"
                        //value={templateType === 'edit' ? templateData.variables : ''}
                        error={!!errors.template_variables}
                        helperText={errors?.template_variables?.message}
                        required
                    />
                )}
            />
            <Controller
                name="template_status"
                control={control}
                render={({ field }) => (
                    <CustomSelect
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="template_status"
                        label="Estado"
                        options={statusOptions}
                        //value={templateType === 'edit' ? templateData.status : field.value}
                        onChange={field.onChange}
                        error={!!errors.template_status}
                        helperText={errors?.template_status?.message}
                    />
                )}
            />
            <Controller
              name="template_body"
              control={control}
              render={({ field }) => (
                <WYSIWYGEditor
                  {...field}
                  //value={templateType === 'edit' ? templateData.body : ''}
                  onChange={(event, newValue) => {
                    if (event.replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, '') === '') {
                      field.onChange(event.replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, ''));
                    } else {
                      field.onChange(event);
                    }
                  }}
                  placeholder="Cuerpo de la plantilla..."
                  error={!!errors.body}
                  helperText={errors?.body?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
