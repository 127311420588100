import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelper,
  FormHelperText,
  IconButton,
  Link
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomTableHead from '@components/CustomTableHead';

import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

import {
  getServiceActivity,
  createServiceActivity,
  deleteServiceActivity,
  updateServiceActivity
} from '../../../store/serviceSlice';
import {
  getRolesDropdown,
} from '../../../store/roleSlice';
import {
  getTemplateDropdown,
} from '../../../store/templateSlice';
import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  {
    id: 'seac_name',
    order: false,
    label: 'Nombre'
  },
  {
    id: 'seac_order',
    order: false,
    label: 'Orden',
  },
  {
    id: 'seac_role_id',
    order: false,
    label: 'Rol',
  },
  {
    id: 'seac_status',
    order: false,
    label: 'Estado',
  },
  {
    id: 'seac_notification',
    order: false,
    label: 'Notificación',
  },
  {
    id: 'seac_email_templates_id',
    order: false,
    label: 'Plantilla',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

const defaultValues = {
  seac_name: '',
  seac_order: '',
  seac_role_id: null,
  seac_status: null,
  seac_notification: null,
};

const schema = yup.object().shape({
  seac_name: yup.string().required('Nombre es requerido'),
  seac_status: yup.object().required('Estado es requerida'),
  seac_order: yup
    .number()
    .typeError('Orden debe ser un número')
    .positive('Orden debe ser un número positivo')
    .required('Orden es requerido'),
  seac_notification: yup.object().required('Notificación es requerida'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function ActivitiesTab(props) {
  const { mainId } = props;
  const dispatch = useDispatch();
  //-------------------------------------------
  const activity = useSelector(({ service }) => service.activity);
  const roles = useSelector(({ role }) => role.rolesDropdown);
  const templates = useSelector(({ template }) => template.templateDropdown);
  //-------------------------------------------
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'seac_id',
  });

  const [items, setItems] = useState([]);
  const [changeDataFile, setChangeDataFile] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  //-------------------------------------------
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema), // Resolver para la validación
  });

  const { reset, handleSubmit, control, setValue, formState: formState } = methods;
  const { errors } = formState;
  //-------------------------------------------
  useEffect(() => {
    async function init() {
      await dispatch(getRolesDropdown());
      await dispatch(getTemplateDropdown());
    }
    init();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (activity) {
        setItems(activity);
      }
    };
    fetch();
  }, [activity]);

  useEffect(() => {
    async function fetch() {
      if (changeDataFile, mainId) {
        await dispatch(getServiceActivity(mainId));
        setChangeDataFile(false)
      }
    }
    fetch();
  }, [changeDataFile, mainId]);

  useEffect(() => {
    const fetch = async () => {
      if (selectRow) {
        reset({
          ...selectRow,
          seac_status: {
            label: selectRow.seac_status === 0 ? "Inactivo" : "Activo",
            value: selectRow.seac_status,
          },
          seac_notification: {
            label: selectRow.seac_notification === 0 ? "Inactivo" : "Activo",
            value: selectRow.seac_notification,
          },
          seac_role_id: selectRow.seac_role_id === null || selectRow.seac_role_id === '' ? null : {
            label: selectRow.fk_role?.role_description,
            value: selectRow.seac_role_id,
          },
          seac_email_templates_id: {
            label: selectRow.fk_email_templates?.template_name,
            value: selectRow.seac_email_templates_id,
          },
        });
      }
    };
    fetch();
  }, [reset, selectRow]);

  /**
  * Function to clean the information
  */
  const cleanData = async () => {
    setSelectRow(null);
    reset(defaultValues);
  };

  /**
* Handles the table sort request.
* @param {string} property - Property to sort the table by.
*/
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };
  // --------------------------------------------
  const optionRoles = roles
    ? roles.map((e) => ({
      value: e.role_id,
      label: e.role_description,
    }))
    : [];
  const optionTemplate = templates
    ? templates.map((e) => ({
      value: e.template_id,
      label: e.template_name,
    }))
    : [];
  const statusOptions = [
    { label: 'Inactivo', value: 0 },
    { label: 'Activo', value: 1 }
  ];
  // --------------------------------------------
  const handleActivity = async (data) => {
    setLoadingFile(true);
    const response = await dispatch(createServiceActivity({
      ...data,
      seac_service_id: mainId,
      seac_status: data.seac_status.value,
      seac_role_id: data.seac_role_id ? data.seac_role_id.value : null,
      seac_notification: data.seac_notification.value,
      seac_email_templates_id: data.seac_email_templates_id ? data.seac_email_templates_id.value : null,
    }));
    if (response) {
      await dispatch(showMessage({ message: 'Actividad creada con exito', variant: 'success' }));
      cleanData();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleActivityUpdate = async (data) => {
    setLoadingFile(true);
    const response = await dispatch(updateServiceActivity({
      ...data,
      seac_service_id: mainId,
      seac_status: data.seac_status.value,
      seac_role_id: data.seac_role_id ? data.seac_role_id.value : null,
      seac_email_templates_id: data.seac_email_templates_id ? data.seac_email_templates_id.value : null,
      seac_notification: data.seac_notification.value,
    },
      data.seac_id
    ));
    if (response) {
      await dispatch(showMessage({ message: 'Actividad actualizada con exito', variant: 'success' }));
      cleanData();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleDelete = async (row) => {
    setLoadingImages((prevLoading) => [...prevLoading, row.seac_id]);
    const response = await dispatch(deleteServiceActivity({ seac_id: row.seac_id }))
    if (response) {
      await dispatch(showMessage({ message: 'Actividad eliminada con exito', variant: 'success' }));
      await dispatch(getServiceActivity(mainId));
    }
  }

  return (
    <div sx={{ width: '600px' }}>
      {/* ---------------------- */}
      <div className=''>
        {/* <p className="text-16 font-bold my-2">Archivos</p> */}
        <div className="mt-4">
          <FormProvider {...methods}>
            <div className="grid xl:grid-cols-1 grid-cols-2 gap-1">

              <div className='w-full pb-4 px-4'>
                <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3">
                  <div>
                    <Controller
                      name="seac_name"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="seac_name"
                          label="Nombre"
                          error={!!errors.seac_name}
                          helperText={errors?.seac_name?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="seac_order"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="seac_order"
                          label="Orden"
                          // value={form.cli_documento}
                          error={!!errors.seac_order}
                          helperText={errors?.seac_order?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="seac_role_id"
                      control={control}
                      render={({ field: { onChange, value, onBlur, ref } }) => (
                        <CustomAutocomplete
                          styles={{ mt: 1, mb: 2 }}
                          label="Rol"
                          id="seac_role_id"
                          value={value || null}
                          options={optionRoles}
                          onChange={(event, newValue) => {
                            onChange(event);
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.key}>
                              {option.label}
                            </li>
                          )}
                          error={!!errors.seac_role_id}
                          helperText={errors?.seac_role_id?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="seac_status"
                      control={control}
                      render={({ field: { onChange, value, onBlur, ref } }) => (
                        <CustomAutocomplete
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          label="Estado"
                          id="seac_status"
                          value={value || null}
                          options={statusOptions}
                          onChange={(event, newValue) => {
                            onChange(event);
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.key}>
                              {option.label}
                            </li>
                          )}
                          error={!!errors.seac_status}
                          helperText={errors?.seac_status?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="seac_notification"
                      control={control}
                      render={({ field: { onChange, value, onBlur, ref } }) => (
                        <CustomAutocomplete
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          label="Notificación"
                          id="seac_notification"
                          value={value || null}
                          options={statusOptions}
                          onChange={(event, newValue) => {
                            onChange(event);
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.key}>
                              {option.label}
                            </li>
                          )}
                          error={!!errors.seac_notification}
                          helperText={errors?.seac_notification?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="seac_email_templates_id"
                      control={control}
                      render={({ field: { onChange, value, onBlur, ref } }) => (
                        <CustomAutocomplete
                          styles={{ mt: 1, mb: 2 }}
                          label="Plantilla Email"
                          id="seac_email_templates_id"
                          value={value || null}
                          options={optionTemplate}
                          onChange={(event, newValue) => {
                            onChange(event);
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.key}>
                              {option.label}
                            </li>
                          )}
                          error={!!errors.seac_email_templates_id}
                          helperText={errors?.seac_email_templates_id?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div className='py-0'>
                    {loadingFile ? (
                      <CircularProgress
                        style={{ color: '#4575D5', marginRight: 4 }}
                        size={24}
                      />
                    ) : (
                      <div className='flex items-center'>
                        <CustomButton
                          label={selectRow === null ? "Guardar" : "Actualizar"}
                          styles={{ mt: 1 }}
                          typebutton={2}
                          onClick={handleSubmit(selectRow === null ? handleActivity : handleActivityUpdate)}
                        />
                        {selectRow && (
                          <p
                            className="mx-4 underline text-base text-gray-600 cursor-pointer"
                            onClick={() => {
                              cleanData();
                            }}
                          >
                            Cancel
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FormProvider>
          <TableContainer>
            <Table size="small" className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items.length > 0 ? items.map((row) => (
                  <TableRow
                    key={row.seac_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.seac_name}</TableCell>
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.seac_order}</TableCell>
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.fk_role?.role_description}</TableCell>
                    <TableCell sx={{ p: '15px 3px' }}>
                      <div
                        style={{ color: row.seac_status === 1 ? "#11AE92" : "#EB5757" }}
                      >
                        {row.seac_status === 1 ? "Activo" : "Inactivo"}
                      </div>
                    </TableCell>
                    <TableCell sx={{ p: '15px 3px' }}>
                      <div
                        style={{ color: row.seac_notification === 1 ? "#11AE92" : "#EB5757" }}
                      >
                        {row.seac_notification === 1 ? "Activo" : "Inactivo"}
                      </div>
                    </TableCell>
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.fk_email_templates?.template_name}</TableCell>
                    <TableCell
                      align="left"
                      sx={{ p: '14px 3px' }}
                    >
                      {loadingImages.includes(row.seac_id) ? (
                        <CircularProgress
                          style={{ color: '#4575D5', marginRight: 4 }}
                          size={24}
                        />
                      ) :
                        <div className='flex'>
                          <IconButton
                            onClick={async () => {
                              handleDelete(row)
                            }}
                          >
                            <DeleteSweepRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={async () => {
                              setSelectRow(row)
                            }}
                          >
                            <BorderColorRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No se han encontrado archivos
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* ---------------------- */}
    </div>
  );
}

export default ActivitiesTab;
