
import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from "clsx";

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, Container, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CustomTextField from '@components/CustomTextField';
import CustomButton from '@components/CustomButton';

import { forgotPassword } from '../../../store/userSlice';
import { showMessage } from '../../../store/messageSlice';

const useStyles = makeStyles({
	typography: {
		textAlign: "left",
		fontSize: 12,
		marginBottom: 8,
		marginTop: 16,
		fontWeight: 500,
		color: '#99A0AB'
	},
	customText: {
		width: '100%',
		'& .MuiFormHelperText-root': {
			color: 'red',
			marginTop: 4,
			fontWeight: 500,
			textAlign: 'right',
		},
	},
	show: {
		'& .MuiInputBase-root': {
			background: '#fff'
		}
	}
});

const defaultValues = {
	email: '',
};

const schema = yup.object().shape({
	email: yup
		.string()
		.email('Email no válido')
		.required('Email es requerido'),
});

function Index() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	});
	const { reset, control, formState, handleSubmit } = methods;
	const { errors } = formState;

	const cleanData = async () => {
		reset(defaultValues);
	};

	const handleAction = async (data) => {
		setLoading(true);
		const result = await dispatch(forgotPassword(data));
		if (result) {
			await dispatch(showMessage({ message: 'Link de recuperación enviado con éxito', variant: 'success' }));
			setLoading(false);
		}
		setLoading(false);
		cleanData();
	};

	return (
		<Container sx={{ mt: 2, mb: 4 }}>
			<Grid
				container
				justifyContent='center'
			>
				<Grid
					item
					xs={11} sm={7} md={3}
					style={{ marginTop: 0 }}
				>
					<div className="flex items-center justify-center mt-10">
						<img src='/bosanet-new-white.png' width='300' height='80' alt='bosanet-new-white.png' />
					</div>
					<div className="flex items-center justify-center mt-10">
						<p className='text-[#fff] text-2xl font-bold text-center'>Ingresa tu email para restablecer contraseña</p>
					</div>
					<FormProvider {...methods}>
						<div className="mt-10 flex flex-col">
							<p className='text-[#fff]'>Email</p>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<CustomTextField
										{...field}
										className={classes.show}
										styles={{ mt: 0, mb: 2, width: '100%' }}
										id="email"
										label=""
										// value={form.cli_nombre_empresa}
										error={!!errors.email}
										helperText={<div className='text-[#fff]'>{errors?.email?.message}</div>}
										required
									/>
								)}
							/>
						</div>
						<div className="flex justify-between mt-5 mb-10 items-center">
							{/* <div className='text-[#fff]'>Ingresa tus datos de registro para empezar a desarrollar tu diagnóstico de exportación.</div> */}
							<div className='w-full'>
								{loading ? (
									<CircularProgress
										style={{ color: '#FFf', marginRight: 4 }}
										size={24}
									/>
								) : (
									<CustomButton
										label="Restablecer contraseña"
										onClick={handleSubmit(handleAction)}
										typebutton={2}
										fullWidth
									/>
								)}
							</div>

						</div>
						<div className="flex justify-center mb-10 items-center">
							<RouterLink style={{ color: '#fff' }} className={clsx(classes.customFontBold, "underline cursor-pointer font-semibold")} to="/sign-in">
								Cancelar
							</RouterLink>
						</div>
					</FormProvider>
				</Grid>
			</Grid>
		</Container>
	);
}
export default Index;