import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import clsx from "clsx";
import { isEmpty } from 'ramda';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelperText,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import { showMessage } from '../../../store/messageSlice';
import { getObservationById, saveObservationInfo } from '../../../store/employeeTrackingSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 600,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

const headCellsTwo = [
  {
    id: 'ass_id',
    order: false,
    label: 'Estado'
  },
  {
    id: 'ass_description',
    order: false,
    label: 'Descripción'
  },
  {
    id: 'ass_observation_type',
    order: false,
    label: 'Tipo'
  },
  {
    id: 'ass_execution_date',
    order: false,
    label: 'Fecha ejecución',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  ass_description: '',
};

const schema = yup.object().shape({
  ass_description: yup.string().required('Descripción es requerido')
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function ObservationDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  console.log('item', item)
  const dispatch = useDispatch();
  const classes = useStyles();

  const observation = useSelector(({ employeeTrack }) => employeeTrack.observation);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'ass_id',
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;
  // --------------------------------------------

  useEffect(() => {
    const fetch = async () => {
      if (observation) {
        setItems(observation);
      }
    };
    fetch();
  }, [observation]);

  useEffect(() => {
    async function init() {
      await dispatch(getObservationById(item.ass_id));
    }
    init();
  }, [open]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  const handleAction = async (data) => {
    setLoading(true);
    const response = await dispatch(saveObservationInfo(data));
    if (response) {
      await dispatch(showMessage({ message: 'Seller activities guardada', variant: 'success' }));
      setLoading(false);
      handleRefreshData();
    } else {
      setLoading(false);
    }
  }

  const handleRefreshData = async () => {
    await dispatch(getObservationById(item.ass_id));
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Observación
        </p>
      </DialogTitle>
      <DialogContent /*sx={{ width: '800px' }}*/ >
        <div className="mt-4">
          <p className="text-16 font-bold my-2 flex">
            <LibraryBooksRoundedIcon style={{ color: '#213462', marginRight: 3 }} />
            Historial
          </p>
          <TableContainer>
            <Table size="small" className="simple">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCellsTwo}
              />
              <TableBody>
                {items.length > 0 ?
                  items.map((row) => (
                    <TableRow
                      key={row.ass_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ p: '15px 10px 15px 3px' }}>
                        <p
                          className={clsx(
                            'inline-flex justify-center items-center px-14 py-1 rounded-full tracking-wide uppercase whitespace-nowrap',
                            row.ass_status === 1 &&
                            'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                            (row.ass_status === 2) &&
                            'bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50',
                          )}
                          style={{ fontSize: 10, maxWidth: 100 }}
                        >
                          {row.ass_status === 2 ? 'Ejecutado' : 'Pendiente'}
                        </p>
                      </TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.ass_description}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.ass_observation_type === 1 ? 'Supplier' : row.ass_observation_type === 2 ? 'Seller' : 'Bosanet'}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.ass_execution_date}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>
                        {row.ass_observation_type === 1 && row.ass_status === 1 && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => handleAction({ ...row, type: 'seller' })}>Enviar Observación seller</Link>
                        )}

                        {row.ass_observation_type === 2 && row.ass_status === 1 && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => handleAction({ ...row, type: 'supplier' })}>Enviar Observación supplier</Link>
                        )}
                      </TableCell>
                    </TableRow>
                  )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
              setLoading(false);
            }}
          >
            Cerrar
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ObservationDialog;
