// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseUrl: process.env.REACT_APP_API_URL,
  endpoints: {
    authenticate: 'authenticate',
    users: 'users',
    sellers: 'sellers',
    cities: 'cities',
    document_types: 'document_types',
    states: 'states',
    countries: 'countries',
    services_group: 'services_group',
    roles: 'roles',
    services: 'services',
    questions: 'questions',
    answers: 'answers',
    persons: 'persons',
    jobs: 'jobs',
    modules: 'modules',
    diagnostics: 'diagnostics',
    payments: 'payments',
    seller_services: 'seller_services',
    seller_tracking: 'seller_tracking',
    seller_activities: 'seller_activities',
    buyers: 'buyers',
    employees: 'employees',
    suppliers: 'suppliers',
    employee_tracking: 'employee_tracking',
    supplier_tracking: 'supplier_tracking',
    forgot_password: 'forgot_password',
    reset_password: 'reset_password',
    validate_token: 'validate_token',
    emailtemplates: 'emailTemplates',

    clientes: 'clientes',
    servicios: 'servicios',
    partner: 'partners',
  },
  url_bucket: process.env.REACT_APP_URL_BUCKET,
  url_bucket_upload: process.env.REACT_APP_URL_BUCKET_UPLOAD,
  url_ftp: 'https://portal.bosanet.com/ftpfiles'
};

export const getAccessToken = async () => {
  try {
    const token = await window.localStorage.getItem('jwt_access_token');
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {
      headers: {
        Authorization: `Basic 1`,
      },
    };
  } catch (error) {
    return {
      headers: {
        Authorization: `Basic 1`,
      },
    };
  }
};
