
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from "clsx";

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, Container, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

import CustomTextField from '@components/CustomTextField';
import CustomButton from '@components/CustomButton';

import { resetPassword, validateToken } from '../../../store/userSlice';
import { showMessage } from '../../../store/messageSlice';

const useStyles = makeStyles({
	typography: {
		textAlign: "left",
		fontSize: 12,
		marginBottom: 8,
		marginTop: 16,
		fontWeight: 500,
		color: '#99A0AB'
	},
	customText: {
		width: '100%',
		'& .MuiFormHelperText-root': {
			color: 'red',
			marginTop: 4,
			fontWeight: 500,
			textAlign: 'right',
		},
	},
	show: {
		'& .MuiInputBase-root': {
			background: '#fff'
		}
	}
});

const defaultValues = {
	new_password: '',
	new_password_confirm: '',
	token: '',
};

const schema = yup.object().shape({
	new_password: yup
		.string()
		.notRequired()
		.optional()
		.when('current_password', (current_password, field) =>
			current_password && current_password.length > 0 && current_password[0] !== ''
				? field
					.required('La nueva contraseña es requerida')
					.min(6, 'Contraseña debe tener al menos 6 caracteres')
					.matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
					.matches(/\d/, 'Contraseña debe tener al menos un número')
					.matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
				: field
		),
	new_password_confirm: yup
		.string()
		.oneOf([yup.ref('new_password'), ''], 'Las contraseñas deben coincidir')
		.when('new_password', (new_password, field) =>
			new_password && new_password.length > 0 && new_password[0] !== ''
				? field.required('Confirmar la nueva contraseña es requerida')
				: field
		),
});

function Index() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	console.log('location', location)
	// const { from } = location.state

	const [loading, setLoading] = useState(false);

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	});
	const { reset, setValue, control, formState, handleSubmit } = methods;
	const { errors } = formState;

	const [values, setValues] = useState({
		showPasswordCurrent: false,
		showPasswordNew: false,
		showPasswordConfirm: false,
	});

	useEffect(() => {
		async function fetch() {
			if (location) {
				const tokenFromUrl = new URLSearchParams(location.search);
				const validate = await dispatch(validateToken({ token: tokenFromUrl.get('token') }));
				if (!validate) {
					return navigate('/sign-in', { replace: true });
				}
				setValue('token', tokenFromUrl.get('token'))
			}
		}
		fetch();
	}, [location]);

	const cleanData = async () => {
		reset(defaultValues);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleClickShowPassword = (params) => () => {
		setValues({
			...values,
			[params]: !values[params],
		})
	}

	const handleAction = async (data) => {
		setLoading(true);
		const result = await dispatch(resetPassword(data));
		if (result) {
			await dispatch(showMessage({ message: 'Contraseña actualizada con exito', variant: 'success' }));
			setLoading(false);
			navigate('/sign-in', { replace: true });
		}
		setLoading(false);
		cleanData();
	};

	return (
		<Container sx={{ mt: 2, mb: 4 }}>
			<Grid
				container
				justifyContent='center'
			>
				<Grid
					item
					xs={11} sm={7} md={3}
					style={{ marginTop: 0 }}
				>
					<div className="flex items-center justify-center mt-10">
						<img src='/bosanet-new-white.png' width='300' height='80' alt='bosanet-new-white.png' />
					</div>
					<div className="flex items-center justify-center mt-10">
						<p className='text-[#fff] text-2xl font-bold text-center'>Restablecer la contraseña</p>
					</div>
					<FormProvider {...methods}>
						<div className="mt-10 flex flex-col">
							<p className='text-[#fff]'>Nueva contraseña</p>
							<Controller
								name="new_password"
								control={control}
								render={({ field }) => (
									<CustomTextField
										{...field}
										styles={{ mt: 1, mb: 2, width: '100%' }}
										type={values.showPasswordNew ? "text" : "password"}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{" "}
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword(
															"showPasswordNew"
														)}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{values.showPasswordNew ? (
															<VisibilityOffRoundedIcon />
														) : (
															<VisibilityRoundedIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										id="new_password"
										// label={'new_password'}
										className={classes.show}
										error={!!errors.new_password}
										helperText={<div className='text-[#fff]'>{errors?.new_password?.message}</div>}
										required
									/>
								)}
							/>
							<p className='text-[#fff]'>Confirmar nueva contraseña</p>
							<Controller
								name="new_password_confirm"
								control={control}
								render={({ field }) => (
									<CustomTextField
										{...field}
										styles={{ mt: 1, mb: 2, width: '100%' }}
										type={values.showPasswordConfirm ? "text" : "password"}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{" "}
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword(
															"showPasswordConfirm"
														)}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{values.showPasswordConfirm ? (
															<VisibilityOffRoundedIcon />
														) : (
															<VisibilityRoundedIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										id="new_password_confirm"
										// label={'confirm_password'}
										className={classes.show}
										error={!!errors.new_password_confirm}
										helperText={<div className='text-[#fff]'>{errors?.new_password_confirm?.message}</div>}
										required
									/>
								)}
							/>
						</div>
						<div className="flex justify-between mt-5 mb-10 items-center">
							{/* <div className='text-[#fff]'>Ingresa tus datos de registro para empezar a desarrollar tu diagnóstico de exportación.</div> */}
							<div className='w-full'>
								{loading ? (
									<CircularProgress
										style={{ color: '#FFf', marginRight: 4 }}
										size={24}
									/>
								) : (
									<CustomButton
										label="Guardar contraseña"
										onClick={handleSubmit(handleAction)}
										typebutton={2}
										fullWidth
									/>
								)}
							</div>

						</div>
						<div className="flex justify-center mb-10 items-center">
							<RouterLink style={{ color: '#fff' }} className={clsx(classes.customFontBold, "underline cursor-pointer font-semibold")} to="/sign-in">
								Regresar a login
							</RouterLink>
						</div>
					</FormProvider>
				</Grid>
			</Grid>
		</Container>
	);
}
export default Index;