import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

/** example of options */
// const options = [
//     { label: 'name', value: 'value' },
// ];

function CustomSelect(props) {
    const { value, onChange, options, label, styles, error } = props;

    const selectStyles = {
        ...styles,
        "&.MuiOutlinedInput-root": {
            minWidth:200,
            "&:hover fieldset": {
                borderColor: "black"
            },
            "&.Mui-focused fieldset": {
                borderColor: "black",
                borderWidth: 1
            },
            "&.MuiInputLabel-root": {
                color: "black"
            },
        },
        "&.MuiFormLabel-root": {
            "&.Mui-focused": {
                color: "black"
            },
        },
    }

    return (
        <FormControl sx={{ minWidth: 195 }} size="small" error={error}>
            <InputLabel sx={selectStyles}>{label}</InputLabel>
            <Select
                {...props}
                value={value}
                onChange={onChange}
                sx={selectStyles}
            >
                {options && options.length > 0 ? options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                )) : []}
            </Select>
        </FormControl>
    );
}

export default CustomSelect;